import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { useInView, InView } from 'react-intersection-observer'
import '../CSS/Style.css'

import Logo from '../Images/Logo.png'
import Img1 from '../Images/Img1.png'
import Img2 from '../Images/Img2.png'
import Img3 from '../Images/Img3.png'
import Img4 from '../Images/Img4.png'
import Img5 from '../Images/Img5.png'
import ServiceImg from '../Images/ServiceImg.png'
import ServiceImg1 from '../Images/ServiceImg1.png'
import ServiceImg2 from '../Images/ServiceImg2.png'
import CaseImg from '../Images/CaseImg.png'
import Mailimg from '../Images/Mailimg.png'
import ArrowImg from '../Images/ArrowImg.png'

import TopMovie from '../Movie/TopMovie.mp4'

export default function Top () {
    return(
        <div className='BaseBack'>
            <video src={TopMovie} className='TopMovie' loop muted autoPlay playsInline controlsList='nofullscreen' type='video' alt='Back'/>
            <div className='Menu'>
                <a href='#1' style={{ textDecoration: 'none', color: 'black' }}>
                    <div className='MenuBtn'>
                        <p className='MenuText'>ネミー物流ソリューションとは</p>
                    </div>
                </a>
                <a href='#2' style={{ textDecoration: 'none', color: 'black' }}>
                    <div className='MenuBtn'>
                        <p className='MenuText'>サービスの流れ</p>
                    </div>
                </a>
                <a href='#3' style={{ textDecoration: 'none', color: 'black' }}>
                    <div className='MenuBtn'>
                        <p className='MenuText'>事例紹介</p>
                    </div>
                </a>
                <a href='#4' style={{ textDecoration: 'none', color: 'black' }}>
                    <div className='MenuBtn'>
                        <p className='MenuText'>Q&A</p>
                    </div>
                </a>
            </div>
            <div className='Information'>
                <p className='InformationLeftText'>新しい快適空間を創造する<span className='InformationLeftArrow'>▶︎▶︎</span></p>
                <img src={Logo} className='InformationLeftLogo'/>
                <p className='InformationRightText'>お問い合わせはこちらから</p>
                <Link to=''><img src={Mailimg} className='InformationRightLogo'/></Link>
            </div>
            <div className='header'>
                <img src={Logo} className='Logo'/>
                <p className='LogoText'>その物流課題、NEMYが解決します。</p>
            </div>
            <div className='ScrollArea'>
                <div className='Page'>
                    {/* <img src={Img1} className='Img1'/> */}
                    <InView>
                        {({ inView, ref, entry }) => (
                        <div ref={ref}>
                            <img src={Img1} className={`Img1`} ref={ref}/>
                        </div>
                        )}
                    </InView>
                    <p className='Img1Text'>
                        『物流コストを抑えられない』<br/>
                        と、諦めていませんか。
                    </p>
                </div>
                <div className='Page page2' id='1'>
                    <div className='Space2'></div>
                    <p className='SolutionTitle'>ネミー物流ソリューションとは</p>
                    <p className='SolutionText'>
                        ロジスティクス戦略の立案、実行、検証、改善<br/>
                        をネミーで実施し、ロジスティクスの最適パタ<br/>
                        ーンを定着するまでワンストップでサポートし<br/>
                        て参ります。
                    </p>
                    <div className='Space2'></div>
                </div>
                <div className='Page page3' id='2'>
                    <p className='ServiceTitle'>サービスの流れ</p>
                    <img src={ServiceImg1} className='ServiceImg'/>
                    <div className='Space5'></div>
                    <p className='ServiceText2 Cost2'>
                        外部の物流管理責任者（CLO）として<br/>
                        活動させて頂きます。
                    </p>
                    <img src={ServiceImg2} className='ServiceImg'/>
                    <div className='Cost2_2'>
                        <div className='Space5'></div>
                        <div className='Space5'></div>
                        <div className='NemyTitleSet ServiceColor'></div>
                        <p className='NemyTitle ServiceColor'>NEMYの課題</p>
                        <div className='Space5'></div>
                        <div className='Space5'></div>
                        <p className='ServiceText2 Cost2'>
                            ・人材不足解消<br/>
                            ・配送最適化<br/>
                            ・倉庫のスペース<br/>
                            ・在庫管理
                        </p>
                        <p className='ServiceText2 Cost5'>
                            ノンアセットであることから、<br/>
                            ご要望に合わせたフレキシブルなサービスを<br/>
                            取り揃えております。
                        </p>
                        <div className='Space5'></div>
                        <div className='Space5'></div>
                    </div>
                </div>
                <div className='Page page4'>
                    <p className='ServiceTitle2'>Service</p>
                </div>
                <div className='Page page5'>
                    <div className='Space5'></div>
                    <InView>
                        {({ inView, ref, entry }) => (
                            <div ref={ref}>
                                <div className={`Service01${inView}`}>
                                    <p className='ServiceText2'>
                                        Service<br/>
                                        <span className='ServiceText2No'>
                                            01<br/>
                                            配送最適化
                                        </span>
                                    </p>
                                    <div className='Space5'></div>
                                    <img src={Img2} className={`Img2`}/>
                                    <p className='Img2Text'>※画像はイメージです</p>
                                    <div className='Space5'></div>
                                    <p className='ServiceText2 CostCenter'>
                                        荷量、納品先に合わせた<br/>
                                        配送方法のご提案、手配を行い、<br/>
                                        配送コストの最適化を行います。
                                    </p>
                                    <p className='ServiceText2 Cost2'>
                                        デジタル技術を用いた経営改革をご提案。<br/>
                                        計画から実行・継続サポート。<br/>
                                        具体的には、<br/>
                                        ・荷物に合わせた、トラック、配送会社の選<br/>
                                        定、価格交渉を行います。
                                    </p>
                                </div>
                            </div>
                        )}
                    </InView>
                    <div className='Space5'></div>
                    <div className='Space5 Border'></div>
                    <div className='Space5'></div>
                    <div className='Space5'></div>
                    <InView>
                        {({ inView, ref, entry }) => (
                            <div ref={ref}>
                                <div className={`Service02${inView}`}>
                                    <p className='ServiceText2'>
                                        Service<br/>
                                        <span className='ServiceText2No'>
                                            02<br/>
                                            倉庫のスペース改善
                                        </span>
                                    </p>
                                    <div className='Space5'></div>
                                    <img src={Img3} className='Img2'/>
                                    <p className='Img2Text'>※画像はイメージです</p>
                                    <div className='Space5'></div>
                                    <p className='ServiceText2 CostCenter'>
                                        倉庫内レイアウト変更<br/>
                                        動線を見直して、保管量の拡大、<br/>
                                        作業効率を高めます。
                                    </p>
                                    <p className='ServiceText2 Cost2'>
                                        御社業務に最適なサードパーティーロジステ<br/>
                                        ィックス（３PL）をアテンドし、常に最適な<br/>
                                        リソースで業務対応（増減する在庫量に対し<br/>
                                        て無駄なく調整が可能）いただけます。ご要<br/>
                                        望（立地、規模）に沿った倉庫のご提案で、<br/>
                                        オーバーフローした在庫の保管をサポートい<br/>
                                        たします。
                                    </p>
                                </div>
                            </div>
                        )}
                    </InView>
                    <div className='Space5'></div>
                    <div className='Space5 Border'></div>
                    <div className='Space5'></div>
                    <div className='Space5'></div>
                    <InView>
                        {({ inView, ref, entry }) => (
                            <div ref={ref}>
                                <div className={`Service03${inView}`}>
                                    <p className='ServiceText2'>
                                        Service<br/>
                                        <span className='ServiceText2No'>
                                            03<br/>
                                            在庫管理
                                        </span>
                                    </p>
                                    <img src={Img4} className='Img2'/>
                                    <p className='Img2Text'>※画像はイメージです</p>
                                    <div className='Space5'></div>
                                    <p className='ServiceText2 CostCenter'>
                                        在庫分析、発注方式の見直し、<br/>
                                        適正な在庫コントロールで<br/>
                                        コスト削減やキャッシュフロー<br/>
                                        改善を行います。
                                    </p>
                                    <p className='ServiceText2 Cost2'>
                                        在庫管理システム（WMS）導入サポートい<br/>
                                        たします。<br/>
                                        具体的には<br/>
                                        ・各部門間の数字の意味合いの統一化（ワン<br/>
                                        ナンバー）を行い、適正な需給を分析し、在<br/>
                                        庫コントロールを行います。
                                    </p>
                                </div>
                            </div>
                        )}
                    </InView>
                    <div className='Space5'></div>
                    <div className='Space5 Border'></div>
                    <div className='Space5'></div>
                    <div className='Space5'></div>
                    <InView>
                        {({ inView, ref, entry }) => (
                            <div ref={ref}>
                                <div className={`Service04${inView}`}>
                                    <p className='ServiceText2'>
                                        Service<br/>
                                        <span className='ServiceText2No'>
                                            04<br/>
                                            人手不足解消
                                        </span>
                                    </p>
                                    <img src={Img5} className='Img2'/>
                                    <p className='Img2Text'>※画像はイメージです</p>
                                    <div className='Space5'></div>
                                    <p className='ServiceText2 CostCenter'>
                                        弊社人材紹介サービス<br/>
                                        『プラグイン』で<br/>
                                        最適な人材をご紹介します。
                                    </p>
                                    <p className='ServiceText2 Cost2'>
                                        御社業務に最適なサードパーティーロジステ<br/>
                                        ィクス（3PL※）をアテンドし、常に最適<br/>
                                        なリソースで業務対応（閑散期はコストを抑<br/>
                                        え繁忙期は必要な人材を無駄なく確保すると<br/>
                                        いった調整が可能）いただけます。
                                    </p>
                                    <div className='Space5'></div>
                                    <p className='ServiceText2 Cost5'>
                                        ※3PLとは、プラグインによる、御社求人活動の代行、<br/>
                                        　人材不足解消を行います。
                                        {/* ※3PLとは、最も効率的な物流戦略の企画立案や物流シス<br/>
                                        テムの構築の提案などを行い、かつ、俺を包括的に受託<br/>
                                        し、実行することをいいます。単なる運送事業者ではなく、<br/>
                                        第三者外部物流部門としてロジスティクス業務を代行し、最<br/>
                                        適な物流サービスを提供します。 */}
                                    </p>
                                </div>
                            </div>
                        )}
                    </InView>
                    <div className='Space5'></div>
                    <div className='Space5'></div>
                </div>
                <div className='Page page4' id='3'>
                    <p className='ServiceTitle2'>事例紹介</p>
                </div>
                <div className='Page page6'>
                    <div className='Space5'></div>
                    <p className='ServiceText2'>
                        Case<br/>
                        <span className='ServiceText2No'>
                            01<br/>
                            NEMY
                        </span>
                    </p>
                    <div className='Space5'></div>
                    <div className='NemyTitleSet'></div>
                    <p className='NemyTitle'>NEMYの課題</p>
                    <div className='Space5'></div>
                    <div className='Space5'></div>
                    <p className='NemyText'>状況分析</p>
                    <p className='NemyText Text2'>STEP1</p>
                    <p className='NemyText Text3'>
                        1.分析<br/>
                        2.荷物の特性、リードタイムなど<br/>
                        3.何をするとコストメリットが<br/>
                        　あるのかを検討<br/>
                        4.納品先
                    </p>
                    <div className='Space5'></div>
                    <div className='Space5'></div>
                    <p className='NemyText Text2'>STEP2</p>
                    <p className='NemyText Text3'>
                        1.荷量に合わせた配送方法を設定<br/>
                        2.配送コスト圧縮<br/>
                        　<span className='FlashText'>（対売上比率8%から3.93%）</span>
                    </p>
                    <div className='Space5'></div>
                    <div className='Space5'></div>
                    <p className='NemyText'>3PL業者選定・価格交渉</p>
                    <p className='NemyText Text2'>STEP</p>
                    <p className='NemyText Text3'>
                        1.遠隔地一ヶ所納品の場合<br/>
                        2.3PL業者選定　価格交渉<br/>
                        3.運用フロー確立<br/>
                        4.実運用
                    </p>
                    <div className='Space5'></div>
                    <div className='Space5'></div>
                    <div className='NemyTitleSet'></div>
                    <p className='NemyTitle'>解決後の結果</p>
                    <div className='Space5'></div>
                    <div className='Space5'></div>
                    <p className='NemyText'>モーダルシフト</p>
                    <p className='NemyText Text4'>
                        遠隔地配送方法を適切に変更。輸送コストの低<br/>
                        減と共に環境負荷の低減。これまでは、目的<br/>
                        地から到着地点までトラックで配送していた<br/>
                        ところを、鉄道や船舶を活用することで、<br/>
                        二酸化炭素排出量を低下させる効果もある。
                    </p>
                    <div className='Space5'></div>
                    <div className='Space5'></div>
                    <InView>
                        {({ inView, ref, entry }) => (
                            <div ref={ref}>
                                <div className='SlideImg'>
                                    <img src={CaseImg} className={`CaseImg${inView}`}/>
                                </div>
                            </div>
                        )}
                    </InView>
                    <div className='Space5'></div>
                    <div className='Space5'></div>
                    <p className='NemyText'>オーバーフロー在庫倉庫の見直し</p>
                    <p className='NemyText Text3'>
                        在庫倉庫お見直し<br/>
                        保管料（ランニングコスト）<br/>
                        交渉を行いコストを削減
                    </p>
                    <div className='Space5'></div>
                    <div className='Space5 Border'></div>
                    <div className='Space5'></div>
                    <div className='Space5'></div>
                    <p className='ServiceText2'>
                        Case<br/>
                        <span className='ServiceText2No'>
                            02<br/>
                            A社
                        </span>
                    </p>
                    <div className='Space5'></div>
                    <div className='NemyTitleSet'></div>
                    <p className='NemyTitle'>A社の課題</p>
                    <div className='Space5'></div>
                    <div className='Space5'></div>
                    <p className='NemyText'>モーダルシフト</p>
                    <p className='NemyText Text3'>配送方法の変更</p>
                    <div className='Space5'></div>
                    <InView>
                        {({ inView, ref, entry }) => (
                            <div ref={ref}>
                                <div className='SlideImg'>
                                    <img src={CaseImg} className={`CaseImg${inView}`}/>
                                </div>
                            </div>
                        )}
                    </InView>
                    <div className='Space5'></div>
                    <div className='Space5'></div>
                    <div className='Space5'></div>
                    <div className='NemyTitleSet'></div>
                    <p className='NemyTitle'>解決後の結果</p>
                    <div className='Space5'></div>
                    <div className='Space5'></div>
                    <p className='NemyText'>発送スパンの見直し</p>
                    <p className='NemyText Text4'>
                        発送スパン見直しを行い、デットスペースを<br/>
                        無くし、生産性の向上を実現
                    </p>
                    <div className='Space5'></div>
                    <div className='Space5'></div>
                    <img src={ArrowImg} className='ArrowImg'/>
                </div>
                <div className='Page page7' id='4'>
                    <p className='ServiceTitle2'>よくある質問</p>
                </div>
                <div className='Page page8'>
                    <div className='Space5'></div>
                    <div className='Space5'></div>
                    <p className='Question'>Q:コンサルタントサービスですか。</p>
                    <p className='Answer'>
                        A:いいえ、社外にロジスティックス専門を持<br/>
                        つイメージです。
                    </p>
                    <div className='Space5'></div>
                    <div className='Space5'></div>
                    <p className='Question'>Q:料金形態を教えてください。</p>
                    <p className='Answer'>
                        A:現在の物流費をお聞きし、適当な金額を<br/>
                        ご提示させていただきます。
                    </p>
                    <div className='Space5'></div>
                    <div className='Space5'></div>
                    <p className='Question'>Q:オンライン面談は対応できますか</p>
                    <p className='Answer'>
                        A:はい、対応できます。
                    </p>
                    <div className='Space5'></div>
                    <div className='Space5'></div>
                    <p className='Question'>
                        Q:ネミーの物流ソリューションは、ト<br/>
                        ラック運送会社が営業していますか。</p>
                    <p className='Answer'>
                        A:トラック運送会社ではありません。<br/>
                        建築空調オプション材メーカーです。<br/>
                        弊社が抱えていた物流コストを改善したこと<br/>
                        がサービス発足の理由です。
                    </p>
                    <div className='Space5'></div>
                    <div className='Space5'></div>
                </div>
                <div className='Page page9'>
                    <div className='Space5'></div>
                    <div className='Space5'></div>
                    <p className='fuder1'>
                        会社名<br/>
                        <span className='fuder2'>ネミー株式会社</span>
                    </p>
                    <div className='Space5'></div>
                    <p className='fuder1'>
                        会社名<br/>
                        <span className='fuder2'>代表取締役社長<br/>根上 幸久 （ねがみ よしひさ）</span>
                    </p>
                    <div className='Space5'></div>
                    <p className='fuder1'>
                        所在地<br/>
                        <span className='fuder2'>〒 153-0044<br/>東京都目黒区大橋二丁目24番3号<br/>中村ビル7階<br/>TEL : 03-5430-3103（代）<br/>FAX : 03-5430-5181</span>
                    </p>
                    <div className='Space5'></div>
                    <p className='fuder1'>
                        資本金<br/>
                        <span className='fuder2'>20,000,000円</span>
                    </p>
                    <div className='Space5'></div>
                    <p className='fuder1'>
                        従業員数<br/>
                        <span className='fuder2'>155名（2024年2月現在）</span>
                    </p>
                    <div className='Space5'></div>
                    <p className='fuder1'>
                        事業内容<br/>
                        <span className='fuder2'>
                            空調・産業機器架台の企画、製造、販売<br/>
                            空調・産業機器用各種汎用架台の販売<br/>
                            防振システムの企画・製造・販売<br/>
                            防音システムの企画・製造・販売<br/>
                            防雪用架台の企画・製造・販売<br/>
                            太陽電池モジュール架台の企画・製造・販売<br/>
                            ネミー式太陽光発電システムの企画・製造・販売<br/>
                            新エネルギーシステムの企画・製造・販売<br/>
                            各種設備・エネルギーシステムにかかわる<br/>
                            コンサルティング 他
                        </span>
                    </p>
                    <div className='Space5'></div>
                    <div className='Space5'></div>
                    <div className='Space5'></div>
                    <div className='Space5'></div>
                    <div className='Space5'></div>
                </div>
            </div>
        </div>
    )
}