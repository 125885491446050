import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import Top from './JS/Top'

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Top />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
